<script setup lang="ts">
import {tripTypes} from '~/utils/flight/search-utils'
import type {TripType} from "~/types/flight/search/TripType";

const props = defineProps<{
  modelValue: TripType,
  hasRoute?: boolean
}>()

const emit = defineEmits(['update:modelValue'])
const trips = tripTypes();

const activeIndex = ref(0)

watch(() => props.modelValue, () => activeIndex.value = trips.findIndex(i => i.name === props.modelValue.name))

const handleTrip = (tripType: TripType, i: number) => {
  activeIndex.value = i
  emit('update:modelValue', tripType)
}
</script>

<template>
  <div class="flex gap-2 items-center">
    <ul class="flex items-center">
      <li
          v-for="(trip, i) in trips"
          :key="i"
          class="relative px-5 py-2.5"
          @click="handleTrip(trip, i)"
      >
        <u-button
            color="white"
            variant="soft"
            :ui="{ base: `${hasRoute ? 'text-white hover:text-white' : 'text-gray-800 hover:text-primary'} ${activeIndex === i ? (hasRoute ? 'text-white' : 'text-primary') : 'text-black'} transition-colors duration-400` }"
            :label="trip.fa_name"
            size="xs"
        />

        <div
            v-if="activeIndex === i"
            class="absolute bottom-0 left-0 right-0 h-[2px] rounded-full transition-all duration-300"
            :class="hasRoute ? 'bg-white' : 'bg-primary'"
        ></div>
      </li>
    </ul>
  </div>
</template>
